/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import PrivacyPolicy from './components/PrivacyPolicy';

const AuthLayout = () => {
	const navigate = useNavigate();

	// Function to handle navigation
	const handlePageClick = (page: string) => {
		navigate(page); // Navigate to the specified page
	};
	useEffect(() => {
		const root = document.getElementById('root');
		if (root) {
			root.style.height = '100%';
		}
		return () => {
			if (root) {
				root.style.height = 'auto';
			}
		}
	}, []);
	return (
		<div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
			<div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
				<div className='d-flex flex-center flex-column flex-lg-row-fluid'>
					<div className='w-lg-500px p-10'>
						<Outlet />
					</div>
				</div>
			</div>
			<div className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2' style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`, height: '100%' }}>
				<div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
					<Link to='/' className='mb-12'>
						<img alt='Logo' src={toAbsoluteUrl('/media/logos/textdrip_connect_logo_white.png')} className='h-200px' />
					</Link>
					<h1 className='text-white fs-2qx fw-bolder text-center mb-7'>Fast, Efficient, and Productive: A New Era of SMS Messaging</h1>
					<div className='text-white fs-base text-center'>
						Our platform provides a seamless{' '}
						<a href='#' className='opacity-75-hover text-warning fw-bold me-1'>web-based SMS solution</a>
						that lets users send and receive messages <br /> directly from their browser. Stay connected anytime, anywhere, without <br />
						relying solely on your mobile device.
					</div>
				</div>
				{/* Add Privacy Policy text here */}
				<div className='text-white text-center' style={{ position: 'absolute', bottom: '20px', right: '20px' }}>
					<span className='cursor-pointer' onClick={() => handlePageClick('/policy/privacy-policy')}>
						Privacy Policy
					</span>
					<span className='cursor-pointer' onClick={() => handlePageClick('/policy/terms-of-service')} style={{ marginLeft: '10px' }}>
						Terms of Service
					</span>
					<span className='cursor-pointer' onClick={() => handlePageClick('/policy/refund-policy')} style={{ marginLeft: '10px' }}>
						Refund Policy
					</span>
				</div>
			</div>
		</div>
	)
}
export { AuthLayout }