import { lazy, FC, Suspense, useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { useAuth } from '../modules/auth';
import { SocketContext } from '../context/socket';
import { toast } from 'react-toastify';
import { Msg } from '../modules/toaster/Msg';

const PrivateRoutes = () => {
	// const DashboardWrapper = lazy(() => import('../pages/dashboard/DashboardWrapper'));
	const InboxWrapper = lazy(() => import('../pages/inbox/InboxWrapper'));
	const ProfileWrapper = lazy(() => import('../pages/profile/ProfileWrapper'));
	const socket = useContext(SocketContext);
	const { currentUser, logout } = useAuth();
	socket.emit('room', currentUser?.uid, (response) => { });
	socket.off('inboundMessage').on('inboundMessage', function (contactDetail, message) {
		toast.success(<Msg title={contactDetail.name} body={message.message} />, {
			position: "bottom-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			theme: "colored"
		});
	});
	socket.off('logoutFromApp').on('logoutFromApp', function () {
		logout();
		toast.success(<Msg title="Logout" body="To use Textdrip Connect, you need to login to the app simultaneously." />, {
			position: "bottom-right",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			theme: "colored"
		});
	});
	return (
		<Routes>
			<Route element={<MasterLayout />}>
				<Route path='auth/*' element={<Navigate to='/inbox' />} />
				{/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
				{/* <Route path='dashboard'
					element={
						<SuspensedView>
							<DashboardWrapper />
						</SuspensedView>
					} /> */}
				<Route
					path='inbox'
					element={
						<SuspensedView>
							<InboxWrapper />
						</SuspensedView>
					}
				/>
				<Route
					path='profile'
					element={
						<SuspensedView>
							<ProfileWrapper />
						</SuspensedView>
					}
				/>
				<Route path='*' element={<Navigate to='/error/404' />} />
			</Route>
		</Routes>
	)
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
	const baseColor = getCSSVariableValue('--bs-primary')
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	})
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}
export { PrivateRoutes }